<template>
  <div
    :class="`${
      mobile
        ? 'w-full rounded-xl shadow-xs'
        : 'w-full overflow-hidden rounded-lg shadow-xs'
    }`"
  >
    <div class="w-full overflow-x-auto">
      <div class="bg-gray-50 py-3 flex w-full justify-between">
        <select
          v-model="perView"
          class="block px-3 mt-1 text-sm border bg-white form-select focus:border-gray-200 focus:outline-none focus:shadow-outline-green dark:focus:shadow-outline-gray"
        >
          <option
            v-for="option of optionsViews"
            :key="option.text"
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </select>
        <div>
          <input
            v-model="query"
            class="border rounded-sm p-2 text-sm"
            placeholder="Filtrar"
          />
          <!--<button v-if="exports" class="text-sm bg-primary p-2 rounded-sm text-white ml-3" @click="exportCSVFile(headers, copyData, 'table')">Exportar para <b>CSV</b></button>-->
        </div>
      </div>
      <hr />
      <table
        v-if="copyData && data && copyData.length > 0"
        class="w-full h-full whitespace-no-wrap"
      >
        <thead>
          <tr
            class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
          >
            <template v-for="header in headers" :key="header.value">
              <th class="px-4 py-4">
                <div
                  class="flex items-center cursor-pointer"
                  @click="
                    () =>
                      sortTable(
                        stateSort == 'dec' ? 'asc' : 'dec',
                        header.value,
                        header.isDate
                          ? 'date'
                          : typeof copyData[0][header.value]
                      )
                  "
                >
                  {{ header.key }}
                  <i class="gg-swap-vertical ml-2"></i>
                </div>
              </th>
            </template>
          </tr>
        </thead>
        <tbody class="bg-white dark:divide-gray-700 dark:bg-gray-800">
          <template v-for="(item, index) in copyData" :key="item">
            <tr
              :class="`text-gray-700 ${
                index % 2 != 0 ? 'bg-gray-100' : ''
              } dark:text-gray-400`"
            >
              <template v-for="object in headers" :key="object">
                <td
                  v-if="object.value != '' && object.photo"
                  :class="`px-2 py-2 text-sm `"
                >
                  <div
                    class="flex-column relative h-full justify-center items-center"
                  >
                    <img class="w-20 rounded-md" :src="item[object.value]" />
                  </div>
                </td>
                <template v-else>
                  <td v-if="object.value != ''" :class="`px-4 py-4 text-sm `">
                    <span v-if="object.date">
                      {{
                        object.deep
                          ? toDate(deep(item, object.value))
                          : toDate(item[object.value])
                      }}
                    </span>

                    <span v-else-if="object.download">
                      <button
                        v-if="item.comprovante"
                        class="text-gray-700 border-none hover:text-primary"
                        @click="object.click(item)"
                      >
                        <i
                          :class="`gg-software-download`"
                          :title="object.title"
                        ></i>
                      </button>
                      <button
                        v-else
                        class="text-gray-700 border-none hover:text-primary hidden"
                      >
                        <i
                          :class="`gg-software-download`"
                          :title="object.title"
                        ></i>
                      </button>
                    </span>
                    <span v-else-if="object.relatorio">
                      <button
                        class="text-gray-700 border-none hover:text-primary mx-1"
                        @click="object.action(item)"
                      >
                        <i
                          :class="`gg-file-document`"
                          :title="object.title"
                        ></i>
                      </button>

                      <button
                        v-if="item.relatorio"
                        class="text-gray-700 border-none hover:text-primary mx-1"
                        @click="object.click(item)"
                      >
                        <i
                          :class="`gg-software-download`"
                          title="Download Relatorio"
                        ></i>
                      </button>

                      <button
                        v-else
                        class="text-gray-700 border-none hover:text-primary mx-1 hidden"
                        @click="object.click(item)"
                      >
                        <i
                          :class="`gg-software-download`"
                          title="Download Relatorio"
                        ></i>
                      </button>
                    </span>

                    <span v-else-if="object.trash">
                      <button
                        v-if="item.tipo_produto != 103"
                        class="text-gray-700 border-none hover:text-primary mx-1 flex justify-center"
                        @click="object.action(item)"
                      >
                        <i :class="`gg-trash`" :title="object.title"></i>
                      </button>
                    </span>

                    <span v-else-if="object.qrcode">
                      <button
                        v-if="item.tipo_produto != 103"
                        class="text-gray-700 border-none hover:text-primary mx-1"
                        @click="object.action(item)"
                      >
                        <i :class="`gg-qr`" :title="object.title"></i>
                      </button>
                    </span>

                    <span v-else-if="object.carrinho">
                      <button
                        class="text-gray-700 border-none hover:text-primary mx-1"
                        @click="object.action(item)"
                      >
                        <i
                          :class="`gg-arrow-right-o`"
                          :title="object.title"
                        ></i>
                      </button>
                    </span>
                    <span
                      v-else-if="object.money"
                      :class="`${
                        creditoDebito ? `text-${item['creditoDebito']}-500` : ''
                      }`"
                    >
                      {{ toMoney(item[object.value]) }}
                    </span>
                    <span v-else-if="object.color">
                      <div
                        :style="`height: 25px; width: 25px; background-color: ${
                          item[object.value]
                        }; border-radius: 5px`"
                      ></div>
                    </span>
                    <span v-else-if="object.document">
                      <template v-if="item[object.value].length > 11">
                        {{ numberToCnpj(item[object.value]) }}
                      </template>
                      <template v-else>
                        {{ numberToCpf(item[object.value]) }}
                      </template>
                    </span>
                    <span
                      v-else
                      :class="` ${
                        object.badge
                          ? `px-3 py-1 rounded-full text-white capitalize bg-${
                              object.badge[item[object.value]]
                            }-500`
                          : ''
                      }`"
                      >{{
                        object.deep
                          ? deep(item, object.value).length > 0
                            ? deep(item, object.value)
                            : ""
                          : object.badge
                          ? item[object.value] === 1
                            ? "Ativado"
                            : item[object.value]
                          : //: (item[object.value].length > 20 ? `${item[object.value].substr(0, 20)}...` : item[object.value])
                            item[object.value]
                      }}
                    </span>
                  </td>
                </template>
              </template>
              <td class="flex relative h-full items-center px-4 py-4 text-sm">
                <div
                  class="flex h-full top-0 justify-start items-center space-x-4 text-sm"
                >
                  <template v-for="option in options" :key="option.icon">
                    <div
                      v-if="
                        option.icon === 'trash-can' && item.tipo_produto != 103
                      "
                      @click="option.action(item)"
                    >
                      <fa
                        :icon="`fa-solid fa-${option.icon}`"
                        :title="option.title"
                        size="xl"
                        :class="` cursor-pointer hover:text-primary`"
                      />
                    </div>
                   
                    <div
                      v-else-if="option.icon === 'qrcode'"
                      @click="option.action(item)"
                    >
                      <fa
                        :icon="`fa-solid fa-${option.icon}`"
                        :title="option.title"
                        size="xl"
                        :class="` cursor-pointer hover:text-primary`"
                      />
                    </div>

                    <div
                      v-else-if="option.icon === 'play'"
                      @click="option.action(item)"
                    >
                      <fa
                        :icon="`fa-solid fa-${option.icon}`"
                        :title="option.title"
                        size="xl"
                        :class="` cursor-pointer hover:text-primary`"
                      />
                    </div>

                    <div
                      v-else-if="option.icon === 'trash'"
                      @click="option.action(item)"
                    >
                      <fa
                        :icon="`fa-solid fa-${option.icon}`"
                        :title="option.title"
                        size="xl"
                        :class="` cursor-pointer hover:text-primary`"
                      />
                    </div>

                    <div
                      v-else-if="option.icon === 'triangle-exclamation'"
                      @click="option.action(item)"
                    >
                      <fa
                        :icon="`fa-solid fa-${option.icon}`"
                        :title="option.title"
                        size="xl"
                        :class="` cursor-pointer hover:text-primary`"
                      />
                    </div>

                    <div
                      v-else-if="option.icon === 'eye'"
                      @click="option.action(item)"
                    >
                      <fa
                        :icon="`fa-solid fa-${option.icon}`"
                        :title="option.title"
                        size="xl"
                        :class="` cursor-pointer hover:text-primary`"
                      />
                    </div>

                    <div
                      v-if="option.icon == 'whatsapp' && item['status'] == 'Cancelado'"
                      @click="option.action(item)"
                    > 
                      <fa
                        icon="fa-solid fa-mobile-screen-button"
                        :title="option.title"
                        size="xl"
                        :class="` cursor-pointer hover:text-primary`"
                      />
                    </div>

                    <router-link
                      v-else-if="option.icon === 'shield'"
                      :to="`${option.path}/${item.id}`"
                    >
                      <fa
                        :icon="`fa-solid fa-${option.icon}`"
                        :title="option.title"
                        size="xl"
                        :class="`cursor-pointer hover:text-primary`"
                      />
                    </router-link>
                    
                    <router-link
                      v-else-if="option.icon === 'clone'"
                      :to="`${option.path}/${item.id}`"
                    >
                      <fa
                        :icon="`fa-solid fa-${option.icon}`"
                        :title="option.title"
                        size="xl"
                        :class="`cursor-pointer hover:text-primary`"
                      />
                    </router-link>
                

                    <router-link
                      v-else-if="item.id && option.path"
                      :to="`${option.path}/${item.id}`"
                    >
                      <fa
                        :icon="`fa-solid fa-${option.icon}`"
                        :title="option.title"
                        size="xl"
                        :class="` cursor-pointer hover:text-primary`"
                      />
                    </router-link>

                    <router-link
                      v-else-if="item.promoters_id && option.path"
                      :to="`${option.path}/${item.promoters_id}`"
                    >
                      <fa
                        :icon="`fa-solid fa-${option.icon}`"
                        :title="option.title"
                        size="xl"
                        :class="` cursor-pointer hover:text-primary`"
                      />
                    </router-link>
                  </template>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <template v-else>
        <div
          class="bg-gray-100 text-center font-medium py-3 flex justify-center items-center opacity-25"
        >
          Nenhum item encontrado... <i class="ml-3 gg-ghost-character"></i>
        </div>
      </template>

      <div
        class="px-4 py-3 flex justify-between text-xs font-semibold h-full w-full tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800"
      >
        <span v-if="data" class="flex items-center col-span-3">
          Mostrando {{ indexPagination * perView }} -
          {{ copyData ? copyData.length : 0 + indexPagination * perView }} de
          {{ data ? data.length : 0 }}
        </span>
        <span class="col-span-2"></span>
        <span class="flex col-span-4 sm:justify-end">
          <nav aria-label="Table navigation">
            <ul class="inline-flex items-center">
              <li>
                <button
                  :class="`px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-${color}`"
                  aria-label="Previous"
                  v-if="indexPagination > 0"
                  @click="indexPagination--"
                >
                  <svg
                    aria-hidden="true"
                    class="w-4 h-4 fill-current"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </li>

              <template v-for="position in sizeOfList" :key="position">
                <li v-if="position - 1 == indexPagination">
                  <button
                    :class="`px-3 py-1 text-white transition-colors duration-150 bg-${color} border border-r-0 border-${color} rounded-md focus:outline-none focus:shadow-outline-${color}`"
                  >
                    {{ position }}
                  </button>
                </li>
                <li v-else>
                  <button
                    v-if="
                      position - 1 <= indexPagination + 3 &&
                      position > indexPagination - 1
                    "
                    @click="indexPagination = position - 1"
                    :class="`px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-${color}`"
                  >
                    {{ position }}
                  </button>
                </li>
              </template>

              <li>
                <button
                  :class="`px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-${color}`"
                  aria-label="Next"
                  v-if="indexPagination < sizeOfList - 1"
                  @click="indexPagination++"
                >
                  <svg
                    class="w-4 h-4 fill-current"
                    aria-hidden="true"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref } from "vue";
import {
  toDate,
  toMoney,
  numberToCpf,
  numberToCnpj,
} from "../../services/formater";
export default {
  props: {
    headers: Array,
    data: Array,
    options: Array,
    color: String,
    exports: Boolean,
    mobile: Boolean,
    creditoDebito: Boolean,
  },

  setup(props) {
    const query = ref("");
    const copyData = ref();
    const perView = ref(25);
    const pagination = ref();
    const indexPagination = ref(0);
    const sizeOfList = ref();
    const stateSort = ref("asc");
    const optionsViews = ref([
      { text: "10", value: "10" },
      { text: "25", value: "25" },
      { text: "50", value: "50" },
      { text: "100", value: "100" },
    ]);

    const convertToCSV = (objArray) => {
      const temp = objArray;
      const array = typeof objArray != "object" ? JSON.parse(temp) : temp;
      let str = "";

      for (let i = 0; i < array.length; i++) {
        let line = "";
        for (const index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }

        str += line + "\r\n";
      }

      return str;
    };

    const exportCSVFile = (headers, items, fileTitle) => {
      // Convert Object to JSON
      const data = [...items];
      const head = headers.filter((el) => el.key != "Opções");
      const headValues = head.map((hd) => hd.value);
      const obj = data.map((el) => {
        const properties = Object.getOwnPropertyNames(el);
        properties.filter((pt) => {
          if (!headValues.includes(pt)) delete el[pt];
        });
        return el;
      });

      obj.unshift(head.map((el) => el.key));
      const jsonObject = JSON.stringify(obj);

      const csv = convertToCSV(jsonObject);

      const exportedFilename = fileTitle + ".csv" || "export.csv";

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilename);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    };

    const deep = (item, pos) => {
      let payload = item;
      pos.split("][").map((el) => {
        payload = payload[el.replace(/[^\w\s]/gi, "")]
          ? payload[el.replace(/[^\w\s]/gi, "")]
          : payload;
      });

      return payload;
    };

    const find = (query) => {
      const response = [];
      props.data.map((item, index) => {
        Object.entries(item).map((element) => {
          if (!response.includes(props.data[index])) {
            if (String(element[1]).toLowerCase() === query.toLowerCase()) {
              response.unshift(props.data[index]); //Items Iguais!
            } else {
              if (
                String(element[1]).toLowerCase().includes(query.toLowerCase())
              ) {
                response.push(props.data[index]); //Items semelhantes!
              }
            }
          }
        });
      });
      return response;
    };

    const divide = (payload, divider) => {
      const divideArray = [...payload];
      return new Array(Math.ceil(divideArray.length / divider))
        .fill(null)
        .map(() => divideArray.splice(0, divider));
    };

    function converterParaData(dataStr) {
      const [dataPart, horaPart] = dataStr.split(" ");
      const [dia, mes, ano] = dataPart.split("/");
      const [hora, minuto] = horaPart.split(":");
      return new Date(ano, mes - 1, dia, hora, minuto, 0);
    }

    const sortTable = (type, key, param) => {
      stateSort.value = type;
      const sortData = [];

      pagination.value.map((el) => {
        el.map((item) => {
          sortData.push(item);
        });
      });
      //return new Date(b.date) - new Date(a.date);
      if (param == "number") {
        if (type == "dec") {
          sortData.sort((a, b) => b[key] - a[key]);
        } else {
          sortData.sort((a, b) => a[key] - b[key]);
        }
      } else if (param == "date") {
        if (type == "dec") {
          sortData.sort((a, b) => {
            const dataA = converterParaData(a.data);
            const dataB = converterParaData(b.data);

            return dataB - dataA;
          });
        } else {
          sortData.sort((a, b) => {
            const dataA = converterParaData(a.data);
            const dataB = converterParaData(b.data);
            return dataA - dataB;
          });
        }
      } else {
        if (type == "dec") {
          sortData.sort((a, b) => String(b[key]).localeCompare(String(a[key])));
        } else {
          sortData.sort((a, b) => String(a[key]).localeCompare(String(b[key])));
        }
      }

      console.log(sortData);

      pagination.value = divide(sortData, Number(perView.value));
      copyData.value = pagination.value[0];
    };
    watch(props, () => {
      copyData.value = props.data;
      const divideData = divide(copyData.value, Number(perView.value));
      copyData.value = divideData[0];
      pagination.value = divideData;
      sizeOfList.value = pagination.value.length;
      indexPagination.value = 0;
    });

    watch(query, () => {
      const result = find(query.value);
      if (result.length > 0) {
        pagination.value = divide(result, perView.value);
        copyData.value = pagination.value[0];
      } else {
        copyData.value = [];
      }
    });

    watch(perView, () => {
      pagination.value = divide(props.data, Number(perView.value));
      copyData.value = pagination.value[0];
      sizeOfList.value = pagination.value.length;
    });

    watch(indexPagination, () => {
      copyData.value = pagination.value[indexPagination.value];
    });

    const ola = (xx) => {
      //console.log(xx)
    };

    return {
      query,
      ola,
      props,
      copyData,
      perView,
      pagination,
      indexPagination,
      sizeOfList,
      sortTable,
      stateSort,
      toDate,
      toMoney,
      numberToCpf,
      numberToCnpj,
      deep,
      exportCSVFile,
      optionsViews,
    };
  },
};
</script>
<style scoped></style>
